import React, { useState, memo } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroInterno from "../components/hero-interno"
import useScroll from "../utility/useScroll"
import pageUrl from "../utility/page-url"
import withPreview from "../utility/with-preview"


const Section = memo(({ section, activeTab }) => {
  return (
    <div>
      {section.content.paragraphs.map((paragraph, j) => (
        <React.Fragment key={j}>
          <span className="scroll-anchor" id={`subguidelines_${activeTab + 1}${j + 1}`}></span>
          <h3 className="subguideline_title">{paragraph.value}</h3>
          <div className="subguideline"
            dangerouslySetInnerHTML={{ __html: paragraph.content.paragraphText.value }}></div>
        </React.Fragment>
      ))}
    </div>
  )
});

const TemplatePaginaPrivacy = ({ data: { meta, page }, pageContext: { langCode }, location }) => {
  const { pageTitle, htmlTitle, hero, sections } = page;
  const [activeTab, setActiveTab] = useState(0);
  const menuFixed = useScroll(700, 50);

  const canonical = (htmlTitle.content && htmlTitle.content.canonicalUrl && htmlTitle.content.canonicalUrl.value) 
    || `${meta.siteUrl}/${pageUrl(page)}`;

  return (
    <Layout langCode={langCode} location={location} tags={page.tags} hideCookieBar={true}>
      <Seo title={htmlTitle.value || pageTitle.value} langCode={langCode} ogImage={htmlTitle.content.ogImage}
        description={htmlTitle.content && htmlTitle.content.metaDescription && htmlTitle.content.metaDescription.value}
        skipEn={pageTitle.content && pageTitle.content.skipEn} canonical={canonical}
        robots={htmlTitle.content && htmlTitle.content.metaRobots && htmlTitle.content.metaRobots.value} />
      {hero && hero.__typename === 'LiferayHerointerno' ? <HeroInterno data={hero} page={page} /> : null}
      {sections ? (
        <div className="privacy">
          <div className="container">
            <div className="row pt-2">
              <div className="col-12 d-flex">
                {sections.map((section, i) => (
                  <button key={i} className={`col-${Math.floor(12 / sections.length)} subtablinks${activeTab === i ? ' active' : ''}`}
                    onClick={() => setActiveTab(i)}>
                    {section.value}
                  </button>
                ))}
              </div>
            </div>
            <div className="row pt-4 pb-5">
              <div className={`col-md-3 d-none d-lg-block banner-Guidelines-menuitem${menuFixed ? ' fixed' : ''}`}>
                <div className="banner-Guidelines-placeholder"></div>
                <div className="menu-fixed no-nav-mobile">
                  <ul className="nj-list-group">
                    {sections[activeTab].content.paragraphs.map((paragraph, j) => (
                      <a href={`#subguidelines_${activeTab + 1}${j + 1}`} className="scroll" key={j} >
                        {paragraph.value}
                      </a>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={`col-lg-9${menuFixed ? ' offset-lg-3' : ''} d-flex banner-Guidelines-guidelines-text`}>
                <Section section={sections[activeTab]} activeTab={activeTab} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Layout>
  );
}

export default withPreview(TemplatePaginaPrivacy, 'page');;

export const query = graphql`query PaginaPrivacyQuery($liferayId: Int!, $langCode: String!){
  meta: gatsbySourceLiferayOptions {
    siteUrl
  }
  page: liferayPaginaprivacy(liferayId: { eq: $liferayId}, langCode: { eq: $langCode}) {
    siteId
    articleId
    langCode
    pageTitle {
      value
      content {
        skipEn
        friendlyUrl {
          value
        }
      }
    }
    htmlTitle {
      value
      content {
        metaDescription {
          value
        }
        canonicalUrl {
          value
        }
      }
      ...PrivacyTitleFragment
    }
    hero {
      __typename
      ...HeroInternoFragment
    }
    sections {
      value 
      content {
        paragraphs {
          value
          content {
            paragraphText {
              value
            }
          }
        }
      }
    }
  }
}`
